import { IonCard, IonCardContent, IonCardTitle, IonIcon } from "@ionic/react";
import usePointerHandler, { Position } from "../services/PointerHandler";
import SocketService from "../services/socket";
import "./DraggableElement.css";
import { chevronBackOutline } from "ionicons/icons";
import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DraggableProps {
    name: string;
    index: number;
    children: React.ReactNode;
    icon: IconDefinition;
    onSendUpdatedPosition: (position: Position) => void;
}

export function DraggableElement({ name, index, children, icon, onSendUpdatedPosition }: DraggableProps) {

    const [ isFocused, setIsFocused ] = React.useState(false);

    const { 
        position, 
        setPosition, 
        setBoundingBox, 
        handleMouseDown, 
        handleMouseUp, 
        handleMouseMove,
    } = usePointerHandler(
        name,
        { x: 40, y: 10 + (index * 60), cursorX: 0, cursorY: 0, visible: false, hasMoved: false },
        onSendUpdatedPosition,
    );

    const boundingBoxRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (boundingBoxRef.current) {
            const width = boundingBoxRef.current.offsetWidth;
            const height = boundingBoxRef.current.offsetHeight;
            setBoundingBox({ x: width, y: height });
        }
    }, [boundingBoxRef.current]);

    React.useEffect(() => {
        SocketService.addElementPositionListener(name, (data) => {
            setPosition({...position, ...data});
        })
        return () => {
            SocketService.removeElementPositionListener(name);
        };
    }, []);

    const mouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        setIsFocused(true);
        handleMouseDown(e);
    }

    const mouseUp = () => {
        setIsFocused(false);
        handleMouseUp();
    }

    if (!position.visible) {
        return (
            <div
                className="bookmark-button-container"
                style={{ top: position.y, left: 0 }}
                onMouseDown={mouseDown}
                onMouseUp={mouseUp}
                onMouseLeave={mouseUp}
                onMouseMove={handleMouseMove}
                onTouchStart={mouseDown}
                onTouchEnd={mouseUp}
                onTouchMove={handleMouseMove}
            >
                <div className="bookmark-button">
                    <FontAwesomeIcon className="minimized-icon" onClick={() => setPosition({ ...position, visible: true })} icon={icon} />
                </div>
            </div>
        );
    }

    return (
        <IonCard className={`draggable-overlay${isFocused ? ' focused' : ''}`} style={{ top: position.y, left: position.x }}>
            <IonCardTitle
                className="draggable-overlay-title"
            >
                {name}
                <IonIcon
                    className="closeIcon"
                    icon={chevronBackOutline}
                    onClick={() => setPosition()}
                    style={{ cursor: "pointer" }}
                />
            </IonCardTitle>
            <div
                ref={boundingBoxRef}
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} 
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseMove}
                onMouseMove={handleMouseMove}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onTouchMove={handleMouseMove}
            />
            <IonCardContent>{children}</IonCardContent>
        </IonCard>
    );
}
