// src/services/socket.ts
import { io, Socket } from "socket.io-client";
import { MappedChunkData } from "../helpers/chunkTools";
import { useState } from "react";
import { b2point } from "../helpers/byteTools";
import config from "../config.json";
// Define the URL of the server
const SERVER_URL = config.backendUrl; // Replace with your server URL

export interface OpStateListenerProps {
    send: number;
    recv: number;
}

export interface ConnectionStateListenerProps {
    prevState: string;
    newState: string;
}

export type OpStateListenerFunc = ({ send, recv }: OpStateListenerProps) => void;
export type ConnectionStateListenerFunc = ({ prevState, newState }: ConnectionStateListenerProps) => void;
export type ChunkSendFunc = (chunks: MappedChunkData[]) => Promise<void>;

class SocketService {

    private static socket: Socket | null = null;
    static boardId = '';
    static configId = '';
    static chunkId = '';
    static sendingOps = 0;
    static receivingOps = 0;
    static prevState = 'disconnected';
    static newState = 'disconnected';
    static opStateListeners: Set<OpStateListenerFunc> = new Set();
    static connectionStateListeners: Set<ConnectionStateListenerFunc> = new Set();

    private static elementPositionListeners: { [elementId: string]: (data: any) => void } = {};
    // Initialize connection to the server
    connect() {
        if (!SocketService.socket) {
            SocketService.socket = io(SERVER_URL, { path: "/api" });

            // Log connection events
            SocketService.socket.on("connect", () => {
                console.log("Connected to server", !!SocketService.socket?.recovered);
                SocketService.prevState = SocketService.newState;
                SocketService.newState = 'connected';
                SocketService.connectionStateChange();
            });

            SocketService.socket.on("disconnect", () => {
                console.log("Disconnected from server");
                SocketService.prevState = SocketService.newState;
                SocketService.newState = 'disconnected';
                SocketService.connectionStateChange();
            });

            SocketService.socket?.on("elementPosition", (element, data) => {
                SocketService.receivingOps++;
                if (SocketService.elementPositionListeners[element]) {
                    SocketService.elementPositionListeners[element](data);
                }
                SocketService.receivingOps--;
            });
        }
    }

    static connectionStateChange() {
        SocketService.connectionStateListeners.forEach(listener => listener({ prevState: SocketService.prevState, newState: SocketService.newState }));
    }

    onConnectionStateChange(callback: (props: { prevState: string, newState: string }) => void) {
        SocketService.connectionStateListeners.add(callback);
    }

    static onOpStateChange(callback: ({ send, recv }: { send: number, recv: number }) => void) {
        SocketService.opStateListeners.add(callback);
    }

    static clearOpStateListeners() {
        SocketService.opStateListeners.clear();
    }

    // Disconnect from the server
    disconnect() {
        if (SocketService.socket) {
            SocketService.socket.disconnect();
            SocketService.socket = null;
        }
    }

    static opStateChanged(send: number, recv: number) {
        SocketService.sendingOps += send;
        SocketService.receivingOps += recv;
        SocketService.opStateListeners.forEach(listener => listener({ send: SocketService.sendingOps, recv: SocketService.receivingOps}));
    }

    async sendChunks(chunkId: string, chunks: any) {
        //console.log('Sending chunks', chunkId, chunks);
        SocketService.opStateChanged(1, 0);
        SocketService.socket?.emit("updateChunk", chunkId, chunks);
        SocketService.opStateChanged(-1, 0);
    }

    async updateParameter(configId: string, data: any) {
        SocketService.opStateChanged(1, 0);
        SocketService.socket?.emit("updateParameter", configId || SocketService.configId, data);
        SocketService.opStateChanged(-1, 0);
    }   

    async sendElementPosition(configId: string, element: string, data: any) {
        SocketService.opStateChanged(1, 0);
        SocketService.socket?.emit("elementPosition", configId || SocketService.configId, element, data);
        SocketService.opStateChanged(-1, 0);
    }

    async createBoard(name: string) {
        SocketService.opStateChanged(1, 0);
        SocketService.socket?.emit("createBoard", {name});
        SocketService.opStateChanged(-1, 0);
    }

    async joinBoard(boardId: string) {
        SocketService.opStateChanged(1, 0);
        SocketService.boardId = boardId;
        SocketService.socket?.emit("joinBoard", boardId);
        SocketService.opStateChanged(-1, 0);
    }

    async leaveBoard(boardId: string) {
        SocketService.opStateChanged(1, 0);
        SocketService.boardId = '';
        SocketService.socket?.emit("leaveBoard", boardId);
        SocketService.opStateChanged(-1, 0);
    }

    async onBoardCreated(callback: (data: any) => void) {
        SocketService.socket?.on("boardCreated", (data) => {
            //console.log("Board created", data);
            const { boardId, chunksId, configId } = data;
            if (boardId) {
                this.setBoardId(boardId);
            }
            if (chunksId) {
                this.setChunkId(chunksId);
            }
            if (configId) {
                this.setConfigId(configId);
            }
            SocketService.opStateChanged(0, 1);
            return callback(data);
            SocketService.opStateChanged(0, -1);
        });
    }

    async onBoardJoined(callback: (data: any) => void) {
        SocketService.socket?.on("boardJoined", (data) => {
            const { boardId, chunksId, configId } = data;
            if (boardId) {
                this.setBoardId(boardId);
            }
            if (chunksId) {
                this.setChunkId(chunksId);
            }
            if (configId) {
                this.setConfigId(configId);
            }
            //console.log('Board joined', data);
            SocketService.opStateChanged(0, 1);
            callback(data);
            SocketService.opStateChanged(0, -1);
        });
    }

    async onUpdateChunk(callback: (data: MappedChunkData[]) => void) {
        SocketService.socket?.on("updateChunk", (data) => {
            SocketService.opStateChanged(0, 1);
            data && callback(data.map((d: { id: Buffer, data: Buffer }) => {
                return ({ id: new Uint8Array(d.id), data: new Uint8Array(d.data) })
            }));
            SocketService.opStateChanged(0, -1);
        });
    }

    async onUpdateParameter(callback: (data: any) => void) {
        SocketService.socket?.on("updateParameter", (data) => {
            SocketService.opStateChanged(0, 1);
            callback(data);
            SocketService.opStateChanged(0, -1);
        });
    }

    async addElementPositionListener(elementId: string, callback: (data: any) => void) {
        SocketService.elementPositionListeners[elementId] = (data) => {
            SocketService.opStateChanged(0, 1);
            callback(data); 
            SocketService.opStateChanged(0, -1);
        };
    }

    getBoardId() {
        return SocketService.boardId;
    }

    setBoardId(boardId: string) {
        //console.log('Setting board id', boardId);
        SocketService.boardId = boardId;
    }

    getChunkId() {
        return SocketService.chunkId;
    }

    setChunkId(chunkId: string) {
        //console.log('Setting chunk id', chunkId);
        SocketService.chunkId = chunkId;
    }

    getConfigId() {
        return SocketService.configId;
    }

    setConfigId(configId: string) {
        //console.log('Setting config id', configId);
        SocketService.configId = configId;
    }




    async removeElementPositionListener(elementId: string) {
        delete SocketService.elementPositionListeners[elementId];
    }

    // Register a listener for drawing path updates from other clients
    async onDrawingPath(callback: (layer: number, color: string, data: any) => void) {
        SocketService.socket?.on("drawingPath", (data: { layer: number, color: string, data: Buffer}) => {
            SocketService.receivingOps++;
            callback(data.layer, data.color, new Uint8Array(data.data));
            SocketService.receivingOps--;
        });
    }

// Optionally add more events here as needed, like "clearBoard" or "undo"
}

const queue: MappedChunkData[] = [];

export const useChunkSendQueue = (
    plotChunk?: (x: number, y: number) => void
) => {
    
    const queueChunks = (sendChunks: ChunkSendFunc, chunks: MappedChunkData[]) => {
        queue.push(...chunks);
        _send(sendChunks);
    }
    
    const _send = async (sendChunks: ChunkSendFunc): Promise<void> => {
        if (queue.length > 0) {
            const chunks = queue.splice(0, 10);
            await sendChunks(chunks);
            if (plotChunk) {
                chunks.forEach((chunk) => {
                    const { x, y } = b2point(chunk.id)
                    plotChunk(x, y);
                });
            }
            return _send(sendChunks);
        }
    };

    return { queueChunks, queue };
}

export const useOpStateListener = () => {
    const [ops, setOps] = useState({ send: 0, recv: 0 });
    SocketService.onOpStateChange((_ops) => {
        if (_ops.send !== ops.send || _ops.recv !== ops.recv) {
            setOps({..._ops});
        } 
    });
    return ops;
}
// Export a single instance of the service
export default new SocketService();
